import { Button, Card, CardBody, CardFooter, CardHeader, Image } from "@nextui-org/react";
import { PuzzleSet } from "../../../types";
import classNames from "classnames";
import { button } from "../cssClasses";
import { getRandomElement } from "../../../utilities/random";
import { useEffect, useRef, useState } from "react";
interface Props {
    puzzleSet: PuzzleSet;
    index: number;
    showInkSplot?: boolean;
    showButton?: boolean;
}

const PuzzleSetCard = ({puzzleSet, 
    index, showInkSplot, showButton}:Props) => {
    const ref = useRef<any>();
    const [height, setHeight] = useState(100);
    const ink_splot = `/assets/ink_splot_${index % 4 + 1}.png`
    const yTranslate = ["-translate-y-1/4", "translate-y-1/4"]
    const xTranslate = ["-translate-x-2/3", "translate-x-2/3"]
    
    useEffect(() => {
        setHeight(ref.current?.offsetHeight ?? 100);
    }, [setHeight, ref])


    return (
        <>
        {showInkSplot && <img alt="ink_splot" src={ink_splot} width={height} height={height as number}
            className={classNames("absolute", getRandomElement(yTranslate), getRandomElement(xTranslate))} 
            style={{zIndex: 1, height: height, width: height, top: ref?.current?.offsetTop ?? 0, left: ref?.current?.offsetLeft ?? 0}}/>}
        <Card ref={ref} shadow="md" className={classNames("flex flex-col justify-between items-center w-1/4 relative max-w-[300px] min-w-[250px] overflow-visible")}>
                   <CardBody className="h-full w-full relative pb-10 overflow-visible bg-white borderImage items-center" style={{zIndex: 2}}>
                <Image
                src={puzzleSet.cover_photo} 
                    width="100%"
                    height="100%"
                className="object-cover w-2/3"
                classNames={{
                wrapper: "w-full justify-center flex flex-row"
                }}
                />
                <CardHeader className="flex flex-col justify-center items-center">
                    <h3 className="text-2xl font-bold"> {puzzleSet.name}</h3>
                    <p className="text-lg w-full text-center">{puzzleSet.description}</p>
                </CardHeader>
                <div className="flex flex-row w-full justify-center space-x-5">
                    <h3 className="font-bold text-lg">Theme:</h3>
                    <p className="text-lg">{puzzleSet.theme}</p>    
                </div>   
                <div className="flex flex-row w-full justify-center space-x-5">
                    <h3 className="font-bold text-lg">Difficulty:</h3>
                    <p className="text-lg">{puzzleSet.difficulty}/100</p>    
                </div>                       

                {showButton && <CardFooter className="w-4/5 absolute -bottom-8" style={{zIndex: 2}}>
                    <Button className={classNames(button.className, "w-full h-10")} style={{zIndex: 2}}>
                        <p className="text-white text-lg">Play this Puzzleset!</p>    
                    </Button>   
                </CardFooter>  }           
            </CardBody>
        </Card>
        </>
     
    )
}

export default PuzzleSetCard;