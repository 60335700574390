import {createApi} from "@reduxjs/toolkit/query/react"
import { ContestInvitation, PuzzleSet } from "../types"
import { AddUserRequest, LoginUserResponse, SubmitAnswerRequest, VerifyUserRequest, getContest } from "./types"
import { Puzzle } from "../types/puzzle"
import baseQueryWithReauth from "./baseQueryWithReauth"
export const apiSlice = createApi({
    reducerPath: "api", 
    baseQuery: baseQueryWithReauth, 
    endpoints: (builder) => ({
        loginUser: builder.mutation<LoginUserResponse, any>({
            query: loginDetails => ({
                url: "auth/login/", 
                method: "POST", 
                body: loginDetails
            })
        }),
        refreshUser: builder.mutation<LoginUserResponse, any>({
            query: loginDetails => ({
                url: "auth/login/refresh/", 
                method: "POST", 
                body: loginDetails
            })
        }),
        getUser: builder.query({
            query: () => ({
                url: "auth/user/", 
                method: "GET"
            })
        }), 
        signUpUser: builder.mutation({
            query: signupDetails => ({
                url: "auth/signup/", 
                method: "POST", 
                body: signupDetails
            }), 
        }),
        checkUser: builder.query({
            query: ({username, email}) => ({
                url: "auth/check/", 
                method: "GET", 
                params: {
                    username: username, 
                    email: email
                }
            })

        }), 
        verifyUser: builder.mutation({
            query:(data:VerifyUserRequest) => ({
                url: "auth/verify-email",
                method: "POST",
                body: data
            })
        }),
        getVerificationToken: builder.mutation({
            query: verificationTokenDetails => ({
                url: "auth/verificationtoken/", 
                method: "POST", 
                body: verificationTokenDetails
            }), 
        }),
        createPuzzleSet: builder.mutation({
            query: puzzleSet => ({
                url: "puzzleset/", 
                method: "POST", 
                body: puzzleSet
            })
        }),
        createAlphaPuzzle: builder.mutation({
            query: alphaPuzzle => ({
                url: "alphapuzzle/create/", 
                method: "POST", 
                body: alphaPuzzle
            })
        }),
        getPuzzleSet: builder.query({
            query: (objectId) => {
                return ({
                    url: `puzzleset/${objectId}`, 
                    method: "GET", 
                })
            }
        }), 
        getPuzzleSetList: builder.query<Array<PuzzleSet>, any>({
            query: () => {
                return ({
                    url: `puzzleset/list`, 
                    method: "GET", 
                })
            }
        }), 
        getPublicContestList: builder.query({
            query: (contestId) => {
                if (contestId) {
                    return ({
                        url: "contests/info", 
                        method: "GET",
                        params: {
                            contestId
                        }
                    })
                } else {
                    return ({
                        url: "contests/info", 
                        method: "GET",
                    })
                }
               
            }
        }), 
        createContest: builder.mutation({
            query: (contest) => ({
                url: "contest", 
                method: "POST", 
                body: contest
            })
        }), 
        getInvitations: builder.query<Array<ContestInvitation>, null>({
           query: () => {
                return ({
                    url: `invitations`,
                    method: "GET"
                })
           } 
        }),
        getContest:builder.query<getContest, string|null>({
            query: (contestId) => {
              if (contestId) {
                return ({
                    url: `contest`, 
                    method: "GET", 
                    params: {
                        contestId
                    }
                })
              }else {
                return ({
                    url: `contest`, 
                    method: "GET", 
                })
            }
        }}), 
        joinContest: builder.mutation({
            query: (contestId) => ({
                url: `contest/join`, 
                method: "POST", 
                body: {
                    'contest': contestId
                }
            })
        }),
        addUser: builder.mutation({
            query: (data:AddUserRequest) => ({
                url: 'contest/users/add',
                method: "POST",
                body: data
            })
        }),
        getPuzzle: builder.query<Puzzle, string>({
            query: (puzzleId) => ({
                url: `puzzle/${puzzleId}`,
                method: "GET", 
            })
        }),
        submitAnswer: builder.mutation({
            query:(data:SubmitAnswerRequest) => ({
                url:'puzzle/answer',
                method:"POST",
                body:data
            })
        })
    })
})

export const {
    useLoginUserMutation,
    useRefreshUserMutation,
    useGetUserQuery, 
    useSignUpUserMutation,
    useCheckUserQuery, 
    useGetVerificationTokenMutation,
    useCreatePuzzleSetMutation, 
    useCreateAlphaPuzzleMutation,
    useGetPuzzleSetQuery, 
    useGetPublicContestListQuery, 
    useGetPuzzleSetListQuery, 
    useCreateContestMutation,
    useGetContestQuery,
    useJoinContestMutation,
    useAddUserMutation,
    useGetInvitationsQuery,
    useGetPuzzleQuery,
    useSubmitAnswerMutation,
    useVerifyUserMutation
} = apiSlice
