import React, {} from "react"; 
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@nextui-org/react";
import classNames from "classnames";
import { useUser } from "../../../hooks/useUser";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import { User } from "../../../types";


const linkStyle = "font-panton text-md text-text"

interface Props {
    user: User|null;
    loggedIn: boolean;
}

function Header({user, loggedIn}:Props) {
    const navigate = useNavigate();
    const {logout} = useUser();
    const location = useLocation();

    const links = [
        {name: "PUZZLE SETS", path: "/puzzlesets"},
        {name: "JOIN CONTEST", path: "/join"},
        {name: "ABOUT", path: "/about"},
    ]

    return (
        <Navbar className="flex flex-row justify-between w-full px-8 pt-4" maxWidth="full">
            <NavbarBrand onClick={() => navigate("/")} className="hover:cursor-pointer">
                <p className="font-bold font-panton text-3xl"> PUZZLE HUNT</p>
            </NavbarBrand>
            <NavbarContent className="gap-24">
                {links.map((link) => (
                    <NavbarItem isActive={location.pathname === link.path}>
                        <NavLink to={link.path}>
                            <p className={classNames(linkStyle)}>{link.name}</p>
                        </NavLink>
                    </NavbarItem>
                
                ))}
            </NavbarContent>
            {!(loggedIn && user) && <NavbarContent className="space-x-3" justify="end">
                <NavLink to="/login">
                    <p className={classNames(linkStyle)}>LOGIN</p>
                </NavLink>
                <Button onPress={() => navigate("/signup")} className="bg-primary text-md text-white rounded-lg w-32 h-8">
                    Sign up 
                </Button>
            </NavbarContent>}
            {loggedIn && user && 
            <NavbarContent className="space-x-3" justify="end">
                <NavLink to="/portal">
                    <p className={classNames(linkStyle)}>PUZZLE PORTAL</p>
                </NavLink>
                <Dropdown>
                    <DropdownTrigger>
                        <Avatar classNames={{
                            base: ["bg-text", "ring-text"]
                        }} isBordered color="primary" showFallback name={user.first_name} src={user.profile_photo}/>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant = "flat">
                    <DropdownItem key="profile" className="h-14 gap-2">
                        <p className="font-semibold">Signed in as</p>
                        <p className="font-semibold">{user.email}</p>
                    </DropdownItem>
                    <DropdownItem key="settings">
                        My Settings
                    </DropdownItem>
                    <DropdownItem key="logout" color="danger" onPress={() => logout()}>
                        Log Out
                    </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavbarContent>}
        </Navbar>

    )
}
const mapStateToProps = (state: RootState) => ({
    loggedIn: state.login.accessToken !== null,
    user: state.login.user
})

export default connect(mapStateToProps, null)(Header);