import { createSlice } from '@reduxjs/toolkit';

export const joinSlice = createSlice({
  name: 'join',
  initialState: {
    contests: []
  },
  reducers: {
    setContests: (state, action) => { 
        state.contests = action.payload;
    }
  },
});

export const { setContests } = joinSlice.actions;



export default joinSlice.reducer;
