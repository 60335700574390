import classNames from "classnames";
import { useGetPuzzleSetListQuery } from "../../../api/apiSlice";
import PuzzleSetCard from "../../shared/PuzzleSetCard";

const PuzzleSets = () => {
    const {data, isSuccess} = useGetPuzzleSetListQuery(null);

    return (
        <div className="flex flex-column h-full justify-start px-20 py-10 ">

            <div className="flex flex-col gap-4 items-start ml-20 w-full">
                <h1 className={classNames("header", "font-panton font-bold text-5xl self-start")}> PUZZLE SETS</h1>
                <p className={"text-2xl text-text font-bold mt-5"}> Explore puzzle sets that you can choose from 
                to solve
                </p>
                <p className="text-lg text-text">Find a theme, a story, or types of puzzles that fit any event or activity.
                </p>
                <div className = "flex flex-row gap-20 flex-wrap mt-10 justify-evenly w-3/4 self-center">
                   
                {isSuccess && data.map((puzzleSet, index) => {
                    return (
                        <PuzzleSetCard puzzleSet={puzzleSet} index = {index} showInkSplot key ={puzzleSet.objectId}/>
                    )
                })}
                </div>
            </div>
        </div>
    );
};
export default PuzzleSets;