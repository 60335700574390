import {Route} from "react-router-dom"; 
import { Routes, Navigate } from "react-router-dom";
import BaseWrapper from "./shared/BaseWrapper";
import Home from "./pages/Home";
// import HomeWrapper from './components/home/homeWrapper';
import About from "./pages/About";
import JoinContest from "./pages/JoinContest";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import VerifyEmail from "./pages/VerifyEmail";
import PuzzleSets from "./pages/PuzzleSets";
import Contests from "./pages/Portal/Contests";
import { connect } from "react-redux";
import { RootState } from "../app/store";
// import PortalJoin from "./components/portal/join/join";
// import Payment from "./components/portal/payment/payment";
// import Account from "./components/portal/account/account";
// import CreateBase from "./components/portal/createBase/create";
// import CreateContest from './components/portal/createContest';
// import CreatePuzzleSet from './components/portal/createPuzzleSet';
// import MyContest from './components/portal/MyContest';
// import JoinContest from './components/portal/JoinContest';
// import PuzzleSets from './components/portal/PuzzleSets';
// import EditContest from "./components/portal/EditContest";
// import Contest from "./components/portal/Contest";
// import Puzzle from "./components/portal/Puzzle";
// import BaseWrapper from "./components/shared/BaseWrapper";
// import VerifyEmailMessage from "./components/VerifyEmailMessage";
// import VerifyEmail from "./components/VerifyEmail";

interface Props {
    enter: boolean;
}

const RouteWrapper = ({enter}:Props) => {
    return (
        <BaseWrapper>
        <Routes>
                <Route index element = {<Home/>} /> 
                <Route path="about" element = {<About/>} />
                <Route path="join" element = {<JoinContest/>} />
                <Route path="login" element = {<Login/>} />
                <Route path="signup" element = {<SignUp/>} />
                <Route path="verify-email" element={<VerifyEmail/>}/>
                <Route path="puzzlesets" element={<PuzzleSets/>} />
                {enter && <Route path = "portal" >
                    <Route index element = {<Contests/>} />
                </Route>}

            {/* 
            // <Route path = "portal">
            //     <Route index element = {<MyContest/>} />
            //     <Route path = "create" >
            //         <Route index element = {<CreateBase/>} />
            //         <Route path = "puzzleset" element = {<CreatePuzzleSet/>} /> 
            //         <Route path = "contest" element = {<CreateContest/>} /> 
            //     </Route>
            //     <Route path = "contest">
            //         <Route index element = {<MyContest/>} />
            //         <Route path = "join" element = {<JoinContest/>} />
            //         <Route path = "join/:contestId" element={<JoinContest/>} />
            //         <Route path = "edit" element={<EditContest />} />
            //         <Route path = ":contestId" element = {<Contest/>} />
            //     </Route>
            //     <Route path = "puzzle">
            //         <Route path = ":puzzleId" element = {<Puzzle/>}> </Route>
            //     </Route>
            //     <Route path = "puzzlesets" element = {<PuzzleSets />} />
            //     <Route path = "account/*" element = {<Account/>}></Route>            
            //     <Route path = "join" element = {<PortalJoin/>}> </Route>
            //     <Route path = "checkout" element = {<Payment/>}></Route>
            // </Route>
            //  */}
            
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
        </BaseWrapper>
    )
}

const mapStateToProps = (state:RootState) => {
    return ({
        enter: state.login.accessToken !== null
    })
}

export default connect(mapStateToProps)(RouteWrapper);