import { createSlice } from '@reduxjs/toolkit';
import { User, DefaultUser } from '../../types';

type loginSliceState = {
  accessToken: string;
  name: string;
  refreshToken: string;
  entered: boolean;
  active: boolean;
  user: User |null;
  verificationToken: string;
}

const initialState: loginSliceState = {
  accessToken: "",
  name: "",
  refreshToken: "", 
  entered: false, 
  active: false, 
  user: null, 
  verificationToken: ""

}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
  setAccess: (state, action) => {
        state.accessToken = action.payload
        if (action.payload != null) {
          state.entered = true
        } else {
          state.entered = false
          state.user = DefaultUser;
        }

    }, 
    setRefresh: (state, action) => {
        state.refreshToken = action.payload
        state.entered = true
    }, 
    setActive: (state, action) => {
      state.active = action.payload
    }, 
    setUser: (state, action) => {
      state.user = action.payload
    }, 
    setVerificationToken: (state, action) => {
      state.verificationToken = action.payload
    }
  },
});

export const { setAccess, 
  setVerificationToken, 
  setRefresh, 
  setActive, 
  setUser
} = loginSlice.actions;



export default loginSlice.reducer;
