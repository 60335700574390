import { Button, Image, Input, Modal, ModalContent, ModalHeader, Select, SelectItem, Slider, Spinner, Textarea, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useCreateContestMutation, useGetPublicContestListQuery, useGetPuzzleSetListQuery } from "../../../api/apiSlice";
import { FieldValues, useForm } from "react-hook-form";
import { Contest, PuzzleSet } from "../../../types";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import styles from './index.module.scss';
import ContestCard from "../../shared/ContestCard";
import { button } from "../../shared/cssClasses";
import PuzzleSetCard from "../../shared/PuzzleSetCard";
import { success } from "../../../hooks/useToast";
import { handleFormError } from "../../../utilities/error";

interface Props {
    loggedIn: boolean;
}

const JoinContest = ({loggedIn}: Props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    const [createContestFunc] = useCreateContestMutation()
    const {data, isLoading, isSuccess} = useGetPublicContestListQuery(null);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {isOpen:isOpenJoin, onOpen:onOpenJoin, onOpenChange:onOpenChangeJoin} = useDisclosure();
    const [puzzleSetId, setPuzzleSetId] = useState("");
    const {register, handleSubmit, formState: {errors}, setValue, getValues} = useForm({})
    const {register:registerJoin, handleSubmit:handleSubmitJoin, 
        formState: {errors:errorsJoin}} = useForm({})
    const {data:puzzleSetData, isSuccess:puzzleSetIsSuccess} = useGetPuzzleSetListQuery(null);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const size = isOpenJoin ? windowWidth * .2 : windowWidth * .25
    const offset = isOpenJoin ? 30 : 60
    const marginHor = (windowWidth - 42 * 16-size + offset)/2 
    const horizontalHeight = isOpenJoin ? 200 : 800
    const marginVer = (windowHeight - horizontalHeight - size + (isOpenJoin ? 0: offset))/2 

    function OnSubmit(values:FieldValues) {
        const payload:any = {
            ...values, 
            start_date: moment(values.start_date).format("YYYY-MM-DD"),
        } 
        console.log(values);
        if (values.end_date !== ""){
            payload["end_date" as keyof typeof payload] = moment(values.end_date).format("YYYY-MM-DD")
        } else {
           delete payload?.end_date
        }
        createContestFunc(payload).unwrap().then(
            (data:Contest) => {
                success("Contest Created!")
                onOpenChange()
            }
        ).catch(error => {
            handleFormError(error);
        })   
    }

    useEffect(() => {
        if (puzzleSetData && puzzleSetData.length > 0){
            setValue("puzzle_set", puzzleSetData[0]?.objectId);
            setPuzzleSetId(puzzleSetData[0]?.objectId);
        }
    }, [puzzleSetIsSuccess, puzzleSetData, setValue])

    const selectedPuzzleSet = puzzleSetData?.find((puzzleSet:PuzzleSet) => puzzleSet.objectId === puzzleSetId)
    return (
        <>
        <div className = "flex flex-col px-20 py-10 h-screen gap-12">
            <div className = "flex flex-row items-center justify-around">
                <div className = "flex flex-col items-start justify-around h-full py-10 w-1/2 gap-2">
                    <h1 className="header font-panton font-bold text-5xl tracking-wider text-left"> MAKE A PRIVATE CONTEST </h1>
                    <h3 className="font-bold text-2xl mt-6"> and play with your friends and family </h3>
                    <p className="text-xl w-3/4 text-justify">
                            Whether you’re playing with friends or running an event, make your own private contest 
                            and play a puzzle set of your choosing!
                    </p>
                    <Button onPress={onOpen} isDisabled={!loggedIn} className={classNames(button.className, "w-3/4")} variant="shadow">
                            <p className="text-xl font-semibold"> 
                            {loggedIn ? "Create Contest" : "Login to Create Contest"}
                        </p>
                    </Button>
                    {loggedIn && <p className = "text-xl w-3/4 text-justify font-bold">Already have a private contest invite? Join <span onClick={onOpenJoin} className="underline">here</span></p>}
                    {!loggedIn && <p className = "text-xl w-3/4 text-justify font-bold">Already have a private contest invite? Login and then join <span className="underline">here</span></p>}
                    <h1 className="header font-panton font-bold text-5xl tracking-wider mt-20 text-left"> JOIN A PUBLIC CONTEST: </h1>
                </div>
                <Image src = "/assets/tesseract.png"  width = {windowWidth * .25}  />   
            </div>
            <div className = "flex flex-col justify-start items-center">
                {isLoading && <Spinner/>}
                {isSuccess && data.length > 0 && data.map((contest:Contest, index:number) => {
                    return (
                        <ContestCard key={contest.objectId} contest={contest} index={index}/>
                    )
                })}
            </div>
        </div>

        <Modal backdrop="opaque"
            classNames={{
                backdrop: ["bg-black/70", "z-50", "absolute", styles.backdrop],
            }}  size="2xl" hideCloseButton={true} className="w-1/2" radius="none" isOpen={isOpen} onOpenChange={onOpenChange}>
                <img alt="ink_splot" src="/assets/ink_splot_1.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, top:marginVer, right: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_2.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, bottom:marginVer, left: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_3.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, top:marginVer, left: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_4.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, bottom:marginVer, right: marginHor}}/>

            <ModalContent style={{zIndex:102}} className={classNames("bg-background py-5 border-8 h-[800px] overflow-y-scroll relative", "borderImage")}>

                <ModalHeader className="items-center flex flex-col">
                    <h1 className="header font-panton font-bold text-4xl tracking-wider text-left"> MAKE A PRIVATE CONTEST </h1>
                </ModalHeader>
                <div className="flex flex-col bg-background items-center" style={{zIndex: 60}}>
                <form id = "createContestForm" 
                     onSubmit={handleSubmit(OnSubmit)}
                     className="min-w-[200px] w-2/3 gap-6 flex flex-col items-center"
                    >
                    <Input
                        label="Contest Name"
                        radius="none"
                        className="flex flex-row justify-between"
                        classNames={{
                            input: ["bg-transparent"],
                            label: ["text-lg"],
                            innerWrapper: ["bg-transparent"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom"],
                            helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                            base: ["relative"]

                        }}
                        isInvalid={!!errors.name}
                        errorMessage={errors.name?.message as string ?? ""}
                        labelPlacement="outside-left"
                        {...register("name", {required: "Name is required"})}
                    />
                    <Textarea
                        label="Description"
                        type="textarea"
                        size="lg"
                        radius="none"
                        multiple={true}
                        className="flex flex-row justify-between gap-4"
                        isInvalid={!!errors.description}
                        errorMessage={errors.description?.message as string ?? ""}

                        classNames={{
                            input: ["bg-transparent"],
                            label: ["text-lg"],
                            innerWrapper: ["bg-transparent"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom",
                            ],
                            helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                            base: ["relative"]
                        }}
                        labelPlacement="outside-left"
                        {...register("description", {required: "Description is required"})}
                    />
                    <Input
                        label="Start Date"
                        type="date"
                        radius="none"
                        className="flex flex-row justify-between"
                        classNames={{
                            input: ["bg-transparent"],
                            label: ["text-lg"],
                            innerWrapper: ["bg-transparent"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom"],
                            helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                            base: ["relative"]

                        }}
                        isInvalid={!!errors.start_date}
                        errorMessage={errors.start_date?.message as string ?? ""}
                        labelPlacement="outside-left"
                        {...register("start_date", {required: "Start Date is required"})}
                    />
                    <Input
                        label="End Date: (Optional)"
                        type="date"
                        radius="none"
                        className="flex flex-row justify-between"
                        classNames={{
                            input: ["bg-transparent"],
                            label: ["text-lg"],
                            innerWrapper: ["bg-transparent"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom"],
                            helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                            base: ["relative"]

                        }}
                        isInvalid={!!errors.end_date}
                        errorMessage={errors.end_date?.message as string ?? ""}
                        labelPlacement="outside-left"
                        {...register("end_date")}
                    />
                    <Slider
                        label="Max Participants" 
                        step={1} 
                        maxValue={100} 
                        minValue={1} 
                        defaultValue={4}
                        onChangeEnd={(value) => setValue("max_participants", value)}
                        />
                    <Select
                        label="Puzzle Set"
                        labelPlacement="outside-left"
                        variant="bordered"
                        radius="lg"
                        onChange={(value) => {
                            setPuzzleSetId(value.target.value);
                            setValue("puzzle_set", value.target.value)
                        }}
                        defaultSelectedKeys={[getValues("puzzle_set")]}
                        value={selectedPuzzleSet?.name}
                        classNames={{
                        base: ["flex", "flex-row", "justify-between", "gap-4", "items-center"],
                        "mainWrapper": ["w-1/2"],
                        trigger: ["border-solid", "border-black", "border-2"]
                        }}
                    >
                        {puzzleSetData ? puzzleSetData.map((puzzleSet:PuzzleSet) => {
                            return(
                                <SelectItem key={puzzleSet.objectId} value={puzzleSet.objectId}>
                                    {puzzleSet.name}
                                </SelectItem>
                            )
                        }): <></>}

                    </Select>

                    {selectedPuzzleSet && 
                    <PuzzleSetCard puzzleSet={selectedPuzzleSet} index={1}/>}
                    
                    <Button type="submit" className={classNames(button.className, "w-2/3" )}>
                        <p> Create Contest</p>
                    </Button>
                </form>
                </div>
            </ModalContent>
        </Modal>

        <Modal backdrop="opaque"
            classNames={{
                backdrop: ["bg-black/70", "z-50", "absolute", styles.backdrop],
            }}  size="2xl" hideCloseButton={true} className="w-1/2" radius="none" isOpen={isOpenJoin} onOpenChange={onOpenChangeJoin}>
                <img alt="ink_splot" src="/assets/ink_splot_1.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, top:marginVer, right: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_2.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, bottom:marginVer, left: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_3.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, top:marginVer, left: marginHor}}/>
                <img alt="ink_splot" src="/assets/ink_splot_4.png" width={size} height={size} className={`absolute`} style={{zIndex: 52, bottom:marginVer, right: marginHor}}/>

            <ModalContent  style={{zIndex:102}} className={classNames("bg-background py-5 border-8 relative h-[300px] items-center", "borderImage")}>

                <ModalHeader className="items-center flex flex-col">
                    <h1 className="header font-panton font-bold text-4xl tracking-wider text-left"> JOIN CONTEST </h1>
                </ModalHeader>
                <div className="flex flex-row bg-background justify-around items-center" style={{zIndex: 60}}>
                    <form id = "joinContestForm" 
                        onSubmit={handleSubmitJoin(OnSubmit)}
                        className="min-w-[200px] px-10 gap-6 flex flex-col items-center"
                        >
                        <Input
                            label="Invite Code"
                            radius="none"
                            className="flex flex-row justify-between"
                            classNames={{
                                input: ["bg-transparent"],
                                label: ["text-lg"],
                                innerWrapper: ["bg-transparent"],
                                inputWrapper: ["bg-transparent", 
                                "shadow-none", 
                                "border-b-solid", 
                                "border-b-2", 
                                "border-b-black",
                                "hover:bg-secondary",
                                "focus:bg-secondary",
                                "borderImageBottom"],
                                helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                                base: ["relative"]

                            }}
                            isInvalid={!!errorsJoin.code}
                            errorMessage={errorsJoin.code?.message as string ?? ""}
                            labelPlacement="outside-left"
                            {...registerJoin("code", {required: "Invite Code is required"})}
                        />
                        

                        <Button type="submit" className={button.className}>
                            <p> Join Contest</p>
                        </Button>
                    </form>
                    <Image src="/assets/exclamation.png" className="transform scale-x-[-1]" width={windowWidth * .075} height={windowWidth * .075}/>
                </div>
            </ModalContent>
        </Modal>
        </>
    )
}

const mapStateToProps = (state:RootState) => {
    return {
        loggedIn: state.login.accessToken != null || !state.login.user
    }
}

export default connect(mapStateToProps, null)(JoinContest);