import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    entered: false, 
  },
  reducers: {
    flipEntered: (state) => {
        state.entered = !state.entered
  }
}});

export const { flipEntered} = homeSlice.actions;



export default homeSlice.reducer;
