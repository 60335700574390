import { Button, Image, Input, Spinner } from "@nextui-org/react";
import { useSignUpUserMutation } from "../../../api/apiSlice";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { button, input } from "../../shared/cssClasses";
import { success } from "../../../hooks/useToast";
import { useState } from "react";
import { handleFormError } from "../../../utilities/error";
import classNames from "classnames";


const SignUp = () => {
    const [signUpFunc, {isLoading}] = useSignUpUserMutation()
    const {register, handleSubmit, formState: {errors}, watch} = useForm({})
    const [submitted, setSubmitted] = useState(false);

    function OnSubmit(values:any) {

        const formData = {
            email: values.email,
            username: values.username,
            password: values.password,
            first_name: values.name.split(" ")[0],
            last_name: values.name.split(" ")[1],
        }
        signUpFunc(formData).unwrap().then(
            (data) => {
                success("Account created successfully!");
                setSubmitted(true);
            }
        ).catch(error => {
            handleFormError(error);
        })   
    }

    const watchPassword = watch('password', ''); // Watching the 'password' input

    const validatePasswordMatch = (value:string) => {
      const password = watchPassword; // Get the value of 'password' input
      return value === password || 'Passwords do not match';
    };

    return (
            <div className = "flex flex-row justify-around items-center min-h-[80vh]">
                <Image src="/assets/exclamation.png" width={200} height={200} />

                <div className = "flex flex-col w-1/3 justify-start h-full">
                    <h1 className = "header font-panton font-bold text-6xl tracking-wider mb-10"> SIGN UP </h1>
                    {!submitted && 
                    <form className="w-full space-y-10 flex flex-col items-center"
                    onSubmit={handleSubmit(OnSubmit)}>
                        <Input
                            label="Name:"
                            radius="none"
                            className="flex flex-row justify-between gap-4"
                            classNames={input.classNames({isError: !!errors.name})}
                            isInvalid={!!errors.name}
                            errorMessage={errors.name?.message as string ?? " "}
                            labelPlacement="outside-left"
                            type="text"
                            {...register("name", {required: "Name is required", 
                            validate: (value:string) => {return value.split(" ").length > 1 || "Please enter your full name"}    
                        })}
                        />
                        <Input
                            label="Email:"
                            radius="none"
                            className="flex flex-row justify-between gap-4"
                            classNames={input.classNames({isError: !!errors.email})}
                            isInvalid={!!errors.email}
                            errorMessage={errors.email?.message as string ?? " "}
                            labelPlacement="outside-left"
                            type="email"
                            {...register("email", {required: "Email is required"})}
                        />
                        <Input
                            label="Username:"
                            radius="none"
                            className="flex flex-row justify-between gap-4"
                            classNames={input.classNames({isError: !!errors.username})}
                            isInvalid={!!errors.username}
                            errorMessage={errors.username?.message as string ?? " "}
                            labelPlacement="outside-left"
                            {...register("username", {required: "Username is required"})}
                        />
                        <Input
                            label="Password:"
                            type="password"
                            radius="none"
                            className="flex flex-row justify-between gap-4"
                            classNames={input.classNames({isError: !!errors.password})}
                            isInvalid={!!errors.password}
                            errorMessage={errors.password?.message as string ?? " "}
                            labelPlacement="outside-left"
                            
                            {...register("password", {required: "Password is required"})}
                        />
                        <Input
                            label="Confirm Password:"
                            type="password"
                            radius="none"
                            className="flex flex-row justify-between gap-4"
                            classNames={input.classNames({isError: !!errors.password2})}
                            isInvalid={!!errors.password2}
                            errorMessage={errors.password2?.message as string ?? " "}
                            labelPlacement="outside-left"
                            {...register("password2", {required: "Confirm Password is required", validate: validatePasswordMatch})}
                        />

                    <Button type="submit" className={classNames(button.className, "w-3/4")}>
                        {isLoading ? <Spinner color="white"/> : <p className="text-xl"> Sign Up</p>  }
                    </Button>

                    <p className="font-bold text-lg">Already have an account? Log in <Link to="/login">here</Link></p>

                    </form>}
                    {submitted && 
                    <h3 className="text-text text-2xl">Awesome! Check your email to make sure we have your contact info right</h3>}
                </div>

                <Image src="/assets/exclamation.png" className="transform scale-x-[-1]" width={200} height={200} />
            </div>
    )
};

export default SignUp;