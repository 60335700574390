import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalName: ""
  },
  reducers: {
    setModalName: (state, action) => {
        state.modalName = action.payload
  }
}});

export const { setModalName} = modalSlice.actions;



export default modalSlice.reducer;
