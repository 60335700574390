import { useNavigate } from "react-router-dom"
import { useRefreshUserMutation } from "../api/apiSlice"
import { setAccess, setRefresh, setUser } from "../app/reducers/loginSlice"
import store from "../app/store"
import { LoginUserResponse } from "../api/types";
import { User } from "../types";
interface useUserHook {
    checkTokens: () => void;
    loggedIn: boolean;
    handleLogin: (data:LoginUserResponse) => void;
    user: User|null;
    logout: () => void;
    setUser: (data:User) => void;
}

export const useUser = (): useUserHook => {
    const [refreshToken] = useRefreshUserMutation()
    const navigate = useNavigate();

    const setRefreshExpiration = () => {
        const currentDate = new Date();
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);
        localStorage.setItem("refreshTime", nextDate.toISOString());
    }

    const checkTokens = () => {
        const token = localStorage.getItem("refreshToken")
        const time = localStorage.getItem("refreshTime")
        // if we have a refresh token, and a valid refresh time that is in the future, refresh the token
        if (token != null && (time != null && new Date(time).getTime()> new Date().getTime() )) {
            refreshToken({refresh:token}).unwrap().then((data) => {
                store.dispatch(setAccess(data["access"]))
                store.dispatch(setRefresh(data["refresh"]))
                localStorage.setItem("refreshToken", data["refresh"])
                setRefreshExpiration()
            }).catch((error) => {
                localStorage.clear()
                store.dispatch(setAccess(null))
                store.dispatch(setRefresh(null))
                navigate("/")
              })
        } else if (token != null) {
            localStorage.clear()
            store.dispatch(setAccess(null))
            store.dispatch(setRefresh(null))
        }  
    }

    const handleLogin = (data:LoginUserResponse) => {
        store.dispatch(setAccess(data["access"]));
        store.dispatch(setRefresh(data["refresh"]));
        store.dispatch(setUser(data["user"]));
        localStorage.setItem("refreshToken", data["refresh"]);
        setRefreshExpiration()
        navigate("/portal/contest");
    }

    const setUserData = (data:User) => {
        store.dispatch(setUser(data))
    }

    const logout = () => {
        store.dispatch(setAccess(null))
        store.dispatch(setRefresh(null))
        localStorage.clear()
    }

    return ({
        checkTokens,
        loggedIn: store.getState().login.accessToken != null,
        handleLogin,
        user: store.getState().login.user,
        setUser: setUserData,
        logout
    })
}