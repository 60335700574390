import { Avatar, Button, Card, CardBody } from "@nextui-org/react";
import { Contest } from "../../../types";
import { useRef } from "react";
import styles from './index.module.scss';
import classNames from "classnames";
import { button } from "../cssClasses";

interface Props {
    contest: Contest;
    index: number;
}

const scss_styles = [styles.greenGradient, styles.blueGradient, styles.orangeGradient, styles.yellowGradient]

const ContestCard = (props:Props) => {
    const cardRef = useRef<any>(null);

    const cardHeight = cardRef.current?.offsetHeight ?? 120;
    let cardWidth = cardRef.current?.offsetWidth ?? 0;

    return (
        <Card  ref = {cardRef}
         className={classNames("flex flex-row justify-space  rounded-none w-full overflow-visible", scss_styles[props.index % scss_styles.length])} radius="none">

            <div className={classNames("w-full h-full", styles.imageBorder)}>
            <CardBody className="flex flex-row justify-between items-center  overflow-visible px-10 py-5">
                <div className="flex flex-col w-1/4">
                    <h2 className="text-2xl font-bold">{props.contest.name}</h2>
                    <p className="text-sm">{props.contest.description}</p>
                </div>
                <div className="flex flex-row w-1/3 justify-between h-full items-center overflow-visible ">
                    <div className="flex flex-col items-end">
                        <p className="text-lg font-bold">Puzzle Set:</p>
                        <p className="text-sm">{props.contest.puzzle_set.name}</p>
                    </div>
                    <Avatar src={props.contest.puzzle_set.cover_photo} classNames={{
                            base: ["bg-text", "ring-text", "ring-4"]
                        }} style={{height: cardHeight * 1.2, width: cardHeight * 1.2, left: cardWidth*.425}} isBordered className={`absolute translate-x-[${cardWidth}rem]`} />
                    <div className="flex flex-col items-start">
                        <p className="text-lg font-bold">Players:</p>
                        <p className="text-sm">{props.contest.total_participants}/{props.contest.max_participants}</p>
                    </div>
                </div>
                <div className="flex flex-col w-1/4 items-end">
                    <Button className={classNames(button.className, "w-1/2")} >Join Contest</Button>
                </div>
            </CardBody>
            </div>
        </Card>
    )
}
export default ContestCard;