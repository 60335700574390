import { Image } from "@nextui-org/react";
import { useVerifyUserMutation } from "../../../api/apiSlice";
import { useEffect } from "react";
import {  useSearchParams } from "react-router-dom";
import {  success } from "../../../hooks/useToast";
import { handleFormError } from "../../../utilities/error";

const VerifyEmail = () => {
    const [verifyFunc, {isError, isSuccess}] = useVerifyUserMutation()
    const [searchParams] = useSearchParams();

    
    useEffect(() => {
        const formData = {
            userId: searchParams.get('userId') ?? '',
            verificationToken: searchParams.get('verificationToken') ?? ''
        }
        const getData = async () => {
            await verifyFunc(formData).unwrap().then(
                (data) => {
                    if (data.message) {
                        success(data.message)
                    } else {
                        success("Account verified successfully!")
                    }
                }
            ).catch(error => {
                handleFormError(error);
            })   
        }
        getData();
    }, [searchParams, verifyFunc])

    return (
            <div className = "flex flex-row justify-around items-center min-h-[80vh]">
                <Image src="/assets/exclamation.png" width={200} height={200} />

                <div className = "flex flex-col w-1/3 h-[500px] justify-start items-center gap-12">
                    <h1 className = "header font-panton font-bold text-6xl tracking-wider mb-10"> Verify Email </h1>
                    {isSuccess && <h3>Welcome to the Party! You’re verified and good to go.</h3>}
                    
                    {isError && <h3 className="text-text text-xl font-bold">We ran into an issue! Request a new 
                    verification email by logging in. </h3>}

                </div>

                <Image src="/assets/exclamation.png" className="transform scale-x-[-1]" width={200} height={200} />
            </div>
    )
};

export default VerifyEmail;