interface Props {
    isError: boolean;
}
export const classNames= (props:Props) => ({
    input: ["bg-transparent"],
    label: ["text-lg", "!text-text"],
    innerWrapper: ["bg-transparent"],
    inputWrapper: [props.isError ? "bg-red-500/25": "bg-transparent", 
    "shadow-none", 
    "border-b-solid", 
    "border-b-2", 
    "border-b-black",
    "hover:bg-transparent",
    "focus:bg-transparent",
    "borderImageBottom",
    ],
    mainWrapper: ["w-2/3"],
    helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]", "w-full", "justify-end", "flex", "flex-row"],
    base: ["relative"]
})