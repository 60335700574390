import React, { useEffect } from 'react';
import './App.css';
import { useUser } from './hooks/useUser';
import RouteWrapper from './components/routes';
import { useGetUserQuery } from './api/apiSlice';
import { Toaster } from 'react-hot-toast';


function App() {
  const {checkTokens, loggedIn, user, setUser, logout} = useUser();
  const {data, isSuccess, isError} = useGetUserQuery(null, {skip: !(loggedIn && !user)})

  useEffect(() => {
    if (!loggedIn){
      checkTokens()
    }
  }, [checkTokens, loggedIn])

  useEffect(() => {
    if (!user && data && isSuccess){
      setUser(data)
    } else if (isError){
      logout()
    }
  }, [data, isSuccess, setUser, user, logout, isError])
  
  useEffect(() => {
    // ReactGA.initialize(
    //   "UA-166016248-7"
    // )
  }, [])


  return (
    
    <div className="App">
      <Toaster />
      <RouteWrapper/>
    </div>
  );
}

export default App;