
import Header from "../Header";
import Footer from "../Footer";


interface Props {
    children: JSX.Element | Array<JSX.Element>;
    className?: string;
    page?: string;
}

export const routeInformation = {
    "/home": {
        footer: false,
        page: 'home'
    }
} 

const BaseWrapper = (props:Props):JSX.Element => {
    return (
        <div className="flex flex-col justify-between h-full min-h-screen">
            <Header
            />
            <div className="flex flex-col justify-start min-h-[80vh]">
            {props.children}
            </div>
            <Footer/>
        </div>
    )
}

export default BaseWrapper;