import { Button, Divider, Image } from "@nextui-org/react";
import classNames from "classnames";
import styles from './index.module.scss';
import { button } from "../../shared/cssClasses";

const Home = () => {
    return (
        <div className="flex flex-col space-y-10 mb-10">
            <div className="flex flex-row w-full items-center justify-around">
                <div className="flex flex-col w-1/2 px-8 space-y-5">
                    <h1 className={classNames(styles.header, 
                        "font-panton font-bold text-6xl self-start drop-shadow-[0_2px_2px_#BD7599]")}> PLAY AND COMPETE</h1>
                    <p className="font-spartan text-justify text-[24px]">
                        Want to play with family or friends? Or what about people from around the globe? 
                        Start solving original puzzles with anyone from anywhere!
                    </p>
                    <div className="flex flex-row gap-10 w-full justify-around">
                        <Button className={classNames(button.className, "w-1/2")} variant="shadow">
                            <p className="text-xl font-semibold"> Get Started</p>
                        </Button>
                        <Button className="bg-secondary text-black w-1/2 h-14 shadow-sm shadow-secondary" variant="shadow">
                            <p className="text-xl font-semibold"> About Us</p>
                        </Button>
                    </div>
                </div>
                <Image src="/assets/puzzle_piece.jpg" width={500} height={300}  />
            </div>
            <div className={classNames(styles.banner, "w-full h-48 flex flex-row justify-center")}>
                <div className="rounded-[40px] w-2/3 h-full bg-background flex flex-col justify-center items-center">
                    <h1 className="text-6xl font-panton font-bold drop-shadow-md"> <span className="text-primary">NEW CONTEST:</span> SHERLOCKED</h1>
                    <p className="text-[20px] w-5/12"> See if you can solve this puzzle contest first in this mind-bending Sherlock Holmes adventure!</p>
                </div>
            </div>
            <div className="flex flex-row space-x-5 px-10 ">
                <div className="flex flex-col w-1/2 px-12 space-y-28 mb-20">
                    {[
                        {
                            title: "Sign Up",
                            description: "Create an account to access our original puzzles and start solving"
                        },
                        {
                            title: "Join a Contest",
                        description: "Enter private contests to beat your friends and family or compete against puzzle solvers across the world in a public contest",
                        },
                        {
                            title: "Solve Puzzles",
                            description: "Think you can crack the code before anyone else? Put money where your mouth is!"
                        }
                    ].map((item, index) => (
                        <div className="flex flex-row w-full space-x-10 items-center" key={index}>
                            <div className={classNames("rounded-3xl aspect-square h-[100px] w-[100px] flex items-center justify-center", styles.step)}>
                                <p className={classNames("font-panton font-bold text-6xl", styles.stepNumber)}>{index + 1}</p>
                            </div> 
                        <div className="flex flex-col items-start space-y-1">
                            <h3 className="text-[25px] font-bold text-text">{item.title}</h3>
                            <Divider className="w-1/2 h-[2px] bg-primary"/>
                            <p className="text-[18px] text-justify ">{item.description}</p>
                        </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-1/2 px-8 space-y-5">

                </div>
            </div>
        </div>
    )
}
export default Home;