import { Divider, Image } from "@nextui-org/react";
import classNames from "classnames";

const titleStyle = "text-2xl font-bold"
const paragraphStyle = "text-xl text-justify"

const About = () => {
    return (
        <div className="flex flex-col px-20 space-y-10 py-20">
            <div className="flex flex-col items-start ml-10 space-y-2">
                <h1 className="header font-panton font-bold text-6xl tracking-wider"> ALL ABOUT US</h1>
                <p className="text-xl"><span className="font-bold">The TL;DR:</span> Two best friends with a passion for creating and solving puzzles.</p>
            </div>
            <div className="flex flex-row justify-around items-center">
                <Image src="/assets/founders.png" width={500} height={500} />

                <div className="flex flex-col items-start space-y-3 w-1/3">
                    <h1 className = {classNames(titleStyle)}> So who are we? </h1>
                    <Divider className="w-1/4 h-[2px] bg-primary"/>
                    <p className={classNames(paragraphStyle)}> 
                        We are the dynamic duo of Andrew Taco Zhang and Avi Garg. Best friends, certified idiots, and amateur competitive foodies.
                    </p>
                    <p className={classNames(paragraphStyle)}>
                        Back in 2020, we built a few online escape rooms that gained some attention. You might have heard of it (Escape It).
                    </p>
                </div>
            </div>
            <div className="flex flex-row justify-around items-center">
                <div className="flex flex-col items-start space-y-3 w-1/3">
                    <h1 className = {classNames(titleStyle)}> What's happening here? </h1>
                    <Divider className="w-1/4 h-[2px] bg-primary"/>
                    <p className={classNames(paragraphStyle)}> 
                        Puzzle Hunt is an online puzzle competition platform where you try and solve puzzles as fast as you can. 
                        You can play with or against friends, family, or even strangers! It’s all up to you.
                    </p>
                    <p className={classNames(paragraphStyle)}>
                        Who knows, maybe you’ll be able to win some money too...
                    </p>
                </div>

                <Image src="/assets/fibonacci.png" width={500} height={500} />
            </div>
            <div className="flex flex-row items-center justify-around">
                <Image src="/assets/cipher.png" width={500} height={500} />
                <div className="flex flex-col items-start space-y-3 w-1/3">
                    <h1 className = {classNames(titleStyle)}> What else is cooking? </h1>

                    <Divider className="w-1/4 h-[2px] bg-primary"/>
                    <p className={classNames(paragraphStyle)}> 
                    We’re constantly trying out new things and just building fun projects just for fun. Check us out at stupidlittleprojects.com to learn more.
                    </p>
                    <p className={classNames(paragraphStyle)}>
                    We try to live up to our name.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default About;