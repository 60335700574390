import { endpoint } from "../endpoints"
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { setAccess, setRefresh } from "../app/reducers/loginSlice"
import { RootState } from "../app/store"
import axios from "axios"

const axiosRefresh = async () => {
  const token = localStorage.getItem("refreshToken")
  return await axios.post(
    endpoint + "auth/login/refresh/", {refresh: token}
  )
}

const baseQuery = fetchBaseQuery(
    {
        baseUrl: endpoint, 
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const state = getState();
            const token = (state as RootState).login.accessToken
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
            return headers
          },
    })

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = 
    await axiosRefresh()
    if (refreshResult.data) {
        var now = new Date()
      // store the new token
      api.dispatch(setAccess((refreshResult.data as any).access))
      api.dispatch(setRefresh((refreshResult.data as any).refresh))
      localStorage.setItem("refreshToken", (refreshResult.data as any).refresh)
      localStorage.setItem("refreshTime", new Date(now.getTime() + 60 * 60 * 1000).toISOString())
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
        localStorage.clear()
        setAccess(null)
        setRefresh(null)

    }
  }
  return result
}

export default baseQueryWithReauth;