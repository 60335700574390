import toast from 'react-hot-toast'
import {DURATION} from '../constants';
import styles from './index.module.scss';

export const success = (message:string) => {
    toast(message, {
        duration: DURATION, 
        className: styles.success
    })
}

export const failure = (message:string) => {
    toast(message, {
        duration: DURATION, 
        className: styles.failure
    })
}

export {}