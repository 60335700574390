import { Button, Image, Input } from "@nextui-org/react";
import { useLoginUserMutation } from "../../../api/apiSlice";
import { useForm } from "react-hook-form";
import { useUser } from "../../../hooks/useUser";
import { Link } from "react-router-dom";
import { button } from "../../shared/cssClasses";
import classNames from "classnames";
import { handleFormError } from "../../../utilities/error";

const Login = () => {
    const [loginFunc] = useLoginUserMutation()
    const {register, handleSubmit, formState: {errors}, setValue} = useForm({})
    const {handleLogin} = useUser();

    function OnSubmit(values:any) {
        loginFunc(values).unwrap().then(
            (data) => {
                handleLogin(data)
            }
        ).catch(error => {
            handleFormError(error);
            setValue("password", "");
        })   
    }

    return (
        <div className = "flex flex-row justify-around items-center min-h-[80vh]">
            <Image src="/assets/exclamation.png" width={200} height={200} />

            <div className = "flex flex-col w-1/3 h-[500px] justify-around">
                <h1 className = "header font-panton font-bold text-6xl tracking-wider mb-10"> LOG IN </h1>
                <form className="w-full space-y-10 flex flex-col items-center"
                onSubmit={handleSubmit(OnSubmit)}>
                    <Input
                        label="Email:"
                        radius="none"
                        className="flex flex-row justify-center gap-4"
                        classNames={{
                            input: ["bg-transparent"],
                            label: ["text-lg"],
                            innerWrapper: ["bg-transparent"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom",
                            ],
                            mainWrapper: ["w-2/3"],
                            helperWrapper: ["absolute", "right-0", "bottom-[-1.5rem]" ],
                            base: ["relative"]

                        }}
                        isInvalid={!!errors.email}
                        errorMessage={errors.email?.message as string ?? ""}
                        labelPlacement="outside-left"
                        {...register("username", {required: "Email is required"})}
                    />
                    <Input
                        label="Password:"
                        type="password"
                        radius="none"
                        className="flex flex-row justify-center gap-4"
                        classNames={{
                            input: ["bg-transparent"],
                            innerWrapper: ["bg-transparent"],
                            label: ["text-lg"],
                            inputWrapper: ["bg-transparent", 
                            "shadow-none", 
                            "border-b-solid", 
                            "border-b-2", 
                            "border-b-black",
                            "hover:bg-secondary",
                            "focus:bg-secondary",
                            "borderImageBottom",
                            ],
                            mainWrapper: ["w-2/3"],
                            helperWrapper: ["absolute", "right-8", "bottom-[-1.5rem]" ],
                            base: ["relative"]

                        }}
                        isInvalid={!!errors.password}
                        errorMessage={errors.password?.message as string ?? ""}
                        labelPlacement="outside-left"
                        {...register("password", {required: "Password is required"})}
                    />

                <Button type="submit" className={classNames(button.className, "w-2/3")}>
                    <p> Log in</p>
                </Button>

                <p className="font-bold text-lg">Don't have an account? Sign up <Link to="/login">here</Link></p>

                </form>
            </div>

            <Image src="/assets/exclamation.png" className="transform scale-x-[-1]" width={200} height={200} />
        </div>

    )
};

export default Login;