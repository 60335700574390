import { useGetContestQuery } from "../../../../api/apiSlice";

const Contests = () => {

    const {data} = useGetContestQuery(null);
    console.log(data);
    return (
        <div className="flex flex-col px-32 space-y-10 py-10">
            <div className="flex flex-col items-start space-y-2">
                <h1 className="header font-panton font-bold text-4xl tracking-wider justify-start"> LIVE PUZZLE CONTESTS</h1>
            </div>
        </div>

    )
}
export default Contests;