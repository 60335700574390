import { failure } from "../hooks/useToast";

interface Error {
    data: any;
}

export const handleFormError = (error:Error) => {
    if (!error.data) {
        failure("Something went wrong")
        return ;
    }
    if (error.data.hasOwnProperty("message")){
        failure(error.data["message" as keyof typeof error.data] as string)
        return ;
    } else {
        for (let key in error.data) {
            if (error.data.hasOwnProperty(key)) {
              failure(error.data[key as keyof typeof error.data] as string)
              return ;
            }
          }
    }
}