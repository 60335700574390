export type User = {
    objectId: string, 
    email: string, 
    username: string, 
    first_name: string, 
    last_name: string, 
    is_staff ?: boolean;
    profile_photo ?: string;
}

export const DefaultUser:User = {
    objectId: "",
    email: '', 
    username: '',
    first_name: '',
    last_name: '', 
    is_staff: false

}