import React from "react"; 
import { faTwitter, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() { 

    return ( 
        <div className = "flex flex-row justify-between items-center bg-primary px-5" style={{zIndex: 3}} >
            <div>
                <p className="text-md text-white"> Daily Puzzle</p>
            </div>
            <div className = "gap-5 flex flex-row">
                    <FontAwesomeIcon icon = {faTwitter} className = "icon" color="white" size = "2x" />
                    <FontAwesomeIcon icon = {faInstagram} className = "icon" color="white"  size = "2x" />
                    <FontAwesomeIcon icon = {faTiktok} className = "icon" color="white" size = "2x" />
            </div>
            <div>
                <img src = "/assets/sliplogo.svg" alt="Logo" className = "w-20 h-20"/>    
            </div>
        </div>
    )
}

 export default Footer;