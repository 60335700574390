import { createSlice } from '@reduxjs/toolkit';
import { Puzzle } from '../../types/puzzle';

interface PuzzleState {
  puzzles: Map<string, Puzzle>;
  shopping_cart: Puzzle[];
  contests: any[];
  contest: any;
}

export const puzzleSlice = createSlice({
  name: 'puzzle',
  initialState: {
    contest: null, 
    puzzles: {} as Map<string, Puzzle>, 
    contests: [], 
    shopping_cart: []
  } as PuzzleState,
  reducers: {
    setContest: (state, action) => { 
        state.contest = action.payload;
    }, 
    setAllContests: (state, action) => {
        state.contests = action.payload;
    },
    addPuzzle: (state, action) => { 
      state.puzzles.set(action.payload["pk"] as string, action.payload)
    }, 
  },
});

export const { setContest, addPuzzle, setAllContests} = puzzleSlice.actions;



export default puzzleSlice.reducer;
