import { configureStore, createStore } from '@reduxjs/toolkit';
import homeReducer from "./reducers/homeSlice";
import loginReducer from "./reducers/loginSlice";
import joinReducer from "./reducers/joinSlice";
import puzzleReducer from "./reducers/puzzleSlice"; 
import modalReducer from "./reducers/modalSlice";
import { apiSlice } from '../api/apiSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer, 
    join: joinReducer,
    puzzle: puzzleReducer, 
    home: homeReducer, 
    modal: modalReducer, 
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware().concat(
      apiSlice.middleware
  ), 
  devTools: true
});


export const homeStore = createStore(homeReducer);
export const loginStore = createStore(loginReducer);
export const joinStore = createStore(joinReducer);
export const puzzleStore = createStore(puzzleReducer);
export const modalStore = createStore(modalReducer)

export type RootState = ReturnType<typeof store.getState>
export default store;